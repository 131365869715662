const getDefaultState = () => {
  return {
    objectTypes: null,
    objects: null,
    selectedObject: null,
    nearObjects: null
  };
};

const state = getDefaultState();

const actions = {
  fetchObjectTypes: ({ commit }) => {
    return api.getObjectTypes().then(res => {
      console.log(res.data);
      commit("setObjectTypes", res.data);
    });
  },
  fetchObjects: ({ commit, dispatch }, { type = 0, page = 1, count, city }) => {
    console.log("disp", type, page);
    commit("setObjects", []);
    return api.getObjects(type, page, count, city).then(res => {
      console.log(res.data);
      if (res.meta.current_page == 1) {
        commit("setObjects", res.data);
        if (res.meta.last_page > 1)
          for (let p = 2; p <= res.meta.last_page; p++) {
            console.log("for", type, p);
            dispatch("fetchObjects", { type: type, page: p });
          }
      } else commit("pushObjects", res.data);
    });
  },
  setSelectedObject: ({ commit }, data) => {
    commit("setSelectedObject", data);
  },
  fetchNearObjects: ({ commit }, id) => {
    return api.getNearObjects(id).then(res => {
      commit("setNearObjects", res.data);
    });
  }
};

const getters = {
  objectTypes: state => state.objectTypes,
  objects: state => state.objects,
  selectedObject: state => state.selectedObject,
  nearObjects: state => state.nearObjects
};

const mutations = {
  setObjectTypes(state, res) {
    state.objectTypes = res;
  },
  setObjects(state, res) {
    state.objects = res;
  },
  pushObjects(state, res) {
    state.objects.push(...res);
  },
  setSelectedObject(state, res) {
    state.selectedObject = res;
  },
  setNearObjects(state, res) {
    state.nearObjects = res;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
