<template>
  <div class="topbar-main" :class="isStop.length !== 0 ? 'topbar-main--mini' : ''">
    <marquee-text
      class="topbar-main__marquee"
      :duration="30"
    >
      {{ creepingLine }}
    </marquee-text>
    <div class="info">
      <div class="info-gerb">
        <img class="info-gerb__gerb" src="../assets/img/gerb-XMAO.svg" />
        <h1 class="info-gerb__title">Ханты - Мансийск</h1>
      </div>

      <div class="datetime">
        <div class="fz-50">{{ time }}</div>
        <div class="fz-24">{{ date }} • {{ weekday }}</div>
      </div>

      <weather />
    </div>
    <div class="nav" v-if="isStop.length === 0">
      <div class="bus-station">
        <img src="../assets/img/busStation.svg" />
        <div class="info-station">
          <p> Остановка </p>
          <h2> {{ GET_STOP_INFO.title }} </h2>
        </div>
      </div>

      <RoutesThroughStation :stop-props="stopProperties" />

<!--      <div class="bus-cards" v-if="CURRENT_ROUTES">-->
<!--        <bus-card-->
<!--          @show-route="showRoute"-->
<!--          :bus-cards="CURRENT_ROUTES"-->
<!--        />-->
<!--      </div>-->
    </div>

    <div class="nav" v-else>
      <div class="place">
        <compass class="arrow" :direction="arrowsAndTitles.arrow_1" />
        <div class="name">{{ arrowsAndTitles.title_1 }}</div>
        <div class="duration">{{ arrowsAndTitles.duration_1 }}</div>
      </div>
      <div class="place">
        <compass class="arrow" :direction="arrowsAndTitles.arrow_2" />
        <div class="name">{{ arrowsAndTitles.title_2 }}</div>
        <div class="duration">{{ arrowsAndTitles.duration_2 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Weather from "@/components/Parts/Weather";
import MarqueeText from "vue-marquee-text-component";
import BusCard from "@/components/Parts/BusCard";
import RoutesThroughStation from "@/components/Parts/RoutesThroughStation";
import Compass from "@/components/Icons/Compass";

export default {
  name: "TopBar",

  props: {
    oldStyle: {
      type: Boolean,
      default: false
    },
  },

  components: {
    Weather,
    MarqueeText,
    BusCard,
    RoutesThroughStation,
    Compass,
  },

  data() {
    return {
      events: [],
      calendar: null,
      creepingLine: process.env.VUE_APP_CREEPING_LINE,

      time: null,
      date: null,
      weekday: null,
    };
  },

  computed: {
    ...mapGetters(['GET_STOP', 'GET_STOP_INFO', 'CURRENT_ROUTES', "isStop", "arrowsAndTitles"]),

    stopProperties() {
      return this.GET_STOP;
    },
  },

  created() {
    setInterval(this.getNow, 1000);
  },

  methods: {
    getNow: function() {
      const currentTime = this.$moment().utcOffset(process.env.VUE_APP_CURRENT_TIMEZONE);

      this.time = currentTime.format('HH:mm');
      this.date = currentTime.format('D MMMM');
      this.weekday = currentTime.format('dddd');
    },

    showRoute(item) {
      this.$emit("show-route", item);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.fz-50 {
  font-size: 50px;
}

.fz-24 {
  font-size: 24px;
  color: rgba(#fff, 0.7);
}

.bus-cards {
  padding: 0 32px 32px;
}

.topbar-main {
  position: relative;
  background: $color_dark;
  height: 500px;
  width: 100%;
  font-family: $ObjectSans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  overflow: hidden;

  &--mini {
    height: 502px;
  }

  &__marquee {
    padding: 16px 0px;
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    color: #fff;
    background-color: #2a2a2c;
    text-transform: uppercase;
  }

  .title {
    padding: 23px 42px;
    background-color: $color_grey_2a;
    font-size: 35px;
    line-height: 36px;
    color: rgba(#fff, 0.97);
  }

  .info {
    width: 100%;
    height: 130px;
    background: $color_grey_2;
    padding: 16px 26px;
    display: flex;

    .event {
      & > div {
        display: flex;
        height: 128px;
      }

      .img {
        width: 128px;
        height: 128px;
        background: #000;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .tag {
          position: relative;
          margin-right: auto;
          margin-bottom: 13px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $color_yellow_dark_2;
          border-radius: 10px;

          span {
            margin: auto 24px;
            font-size: 20px;
            font-weight: 500;
            line-height: unset;
            letter-spacing: -0.2px;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            padding-top: 4px;
          }
        }

        .name {
          max-width: 250px;
          font-size: 24px;
          line-height: 1.15;
          letter-spacing: 0.1px;
          color: rgba(#fff, 0.7);
        }
      }
    }

    .datetime {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 32px;
      border-right: 1px solid #49494B;
      text-align: right;

      .time {
        padding-bottom: 8px;

        span {
          font-size: 72px;
        }
      }
    }

    .info-gerb {
      display: flex;
      align-items: center;
      flex: 1;

      &__gerb {
        width: 73px;
        height: 95px;
      }

      &__title {
        padding-left: 20px;
        margin: 0;
        font-size: 30px;
        font-weight: normal;
        line-height: 50px;
        letter-spacing: -0.5px;
      }
    }
  }

  .nav {
    width: 100%;

    .place {
      position: relative;
      padding: 40px 32px;
      display: flex;
      align-items: center;
      color: rgba(#fff, 0.97);

      &:first-child::after {
        content: "";
        width: 904px;
        height: 4px;
        background: $color_grey_2;
        position: absolute;
        top: 150px;
        left: 144px;
      }

      .arrow {
        margin-right: 40px;
      }

      .name {
        width: 752px;
        font-size: 44px;
        line-height: 1.09;
        letter-spacing: -0.5px;
      }

      .duration {
        width: 152px;
        text-align: right;
        font-size: 35px;
        line-height: 1.03;
        letter-spacing: -0.5px;
        font-weight: 500;
      }
    }

    .bus-station {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 32px 20px;

      .info-station {
        padding-left: 19px;

        p {
          font-size: 20px;
          font-weight: normal;
          color: rgba(#fff, 0.7);
          text-transform: uppercase;
        }

        h2 {
          margin: 0;
          font-size: 54px;
          font-weight: normal;
          line-height: 60px;
        }
      }

      .bus-cards {
        margin-top: 31px;
      }
    }
  }
}
</style>
