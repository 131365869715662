export default {
  // baseURL: `https://fe26.ru/api`,
  // baseURL: `https://murmansk.travel/api`,
  // baseURL: `https://tourism.murmansk.rusatom.dev/api`,
  baseTransportURL: process.env.VUE_APP_TRANSPORT_URL,
  baseURL: process.env.VUE_APP_BASE_URL,
  baseSocketURL: process.env.VUE_APP_BASE_SOCKET_URL,
  socketSecure: Number(process.env.VUE_APP_SOCKET_SECURE),


  headers: {
    "Content-Type": "application/json"
  },

  timeout: 30000,

  withCredentials: false, // default

  responseType: "json", // default

  maxContentLength: 2000,

  validateStatus: function(status) {
    return status >= 200 && status < 300; // default
  },

  maxRedirects: 5 // default
};
