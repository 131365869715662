import axios from "axios";

const state = {
  places: [],
  loader: false,
  types: [],
  tripOrPlace: {},
  nearTripsOrPlaces: [],
  notification: ""
};

const getters = {
  PLACES: (state) => {
    return state.places;
  },
  TYPES: (state) => {
    return state.types;
  },
  TRIP_OR_PLACE: (state) => {
    return state.tripOrPlace;
  },
  NEAR_TRIPS_OR_PLACES: (state) => {
    return state.nearTripsOrPlaces;
  },
  LOADER: (state) => {
    return state.loader;
  },
  NOTIFICATION: (state) => {
    return state.notification;
  }
};

const actions = {
  GET_PLACES: async ({commit}, payload) => {
    const getCity = payload.city ? `&city_id=${payload.city}` : "";
    const getType = payload.type && payload.type !== 0 ? `&type_id=${payload.type}` : "";
    const imageSize = payload.resolution ? `&resolution=${payload.resolution}` : '';
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/places?show=id,title,address,city,city_id,description,images,location,tags,entity,title,type,contacts,work_hours&page=${payload.page}&count=${payload.count}${getType}${getCity}${imageSize}`);
    commit("SET_LOADER", true)

    setTimeout(() => {
      if (data.result === "ok") {
        commit("SET_PLACES", data.data);
        commit("SET_LOADER", false);
      }
    }, 500)
  },

  GET_PLACES_TYPE: async ({dispatch, commit}, payload) => {
    commit("SET_NOTIFICATION", "");
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/places?type_id=${payload.object.id}&page=${payload.page}&count=${payload.count}`);
    commit("SET_LOADER", true);

    if (data.result === "ok") {
      setTimeout(() => {
        if (data.data.length < 1) {
          commit("SET_NOTIFICATION", "Объектов пока нет, попробуйте другой запрос");
        }
        commit("SET_PLACES", data.data);
        commit("SET_LOADER", false);
      }, 500)
    }
  },

  GET_TYPES: async ({commit}) => {
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/types`);
    commit("SET_TYPES", data.data);
  },

  GET_TRIP_OR_PLACE: async ({dispatch, commit}, payload) => {
    if (payload.entity === "places") {
      let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/places/${payload.id}?resolution=medium`);
      commit("SET_TRIP_OR_PLACE", data.data);
      dispatch("GET_NEAR_TRIPS_OR_PLACES", payload);
    }

    if (payload.entity === "trips") {
      let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/trips/${payload.id}?resolution=medium`);
      commit("SET_TRIP_OR_PLACE", data.data);
      dispatch("GET_NEAR_TRIPS_OR_PLACES", payload);
    }
  },

  GET_NEAR_TRIPS_OR_PLACES: async ({commit}, payload) => {
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/${payload.entity}/?near=${payload.id}&count=4&resolution=medium`);
    commit("SET_NEAR_TRIPS_OR_PLACES", data.data);
  }
};

const mutations = {
  SET_PLACES: (state, payload) => {
    state.places = state.places.concat(payload);
  },
  SET_PLACES_TYPE: (state, payload) => {
    state.places = payload;
  },

  SET_TYPES: (state, payload) => {
    state.types = payload;
  },
  SET_TRIP_OR_PLACE: (state, payload) => {
    state.tripOrPlace = payload;
  },
  SET_NEAR_TRIPS_OR_PLACES: (state, payload) => {
    state.nearTripsOrPlaces = payload;
  },
  SET_LOADER: (state, payload) => {
    state.loader = payload;
  },
  SET_CLEAR_PLACES: (state) => {
    state.places = [];
  },
  SET_NOTIFICATION: (state, payload) => {
    state.notification = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
