<template>
  <div v-html="content.replace(/<([^>]+)>/ig,'')"></div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ""
    }
  }
};
</script>