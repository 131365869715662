<template>
  <div id="app">
    <top-bar
      @show-route="showRoute"
    />
    <template>
      <router-view
          :route="route"
      />
      <bottom-bar />
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import TopBar from "@/components/TopBar";
import BottomBar from "@/components/BottomBar";

import {setSocketRoutes} from "@/api/socket";
import TopBarPage from "@/components/TopBarPage.vue";

export default {
  components: {TopBar, BottomBar },
  data() {
    return {
      route: null,
    }
  },
  computed: {
    ...mapGetters([
      "stopId"
    ]),

    stopId() {
      return this.$route.query.stop_id
    }
  },
  beforeCreate() {
    this.$router.onReady(() => {
      this.$store.dispatch("fetchPanel", {
        panelId: this.$route.query.panel_id,
        stopId: this.$route.query.stop_id,
        isStop: this.$route.query.is_stop ? this.$route.query.is_stop : '',
        panelCoords: this.$route.query.panel_coords ? this.$route.query.panel_coords : '',
        nearStopCoords: this.$route.query.near_stop_coords ? this.$route.query.near_stop_coords : '',
        arrowsAndTitles: {
          title_1: this.$route.query.title_1 ? this.$route.query.title_1 : '',
          title_2: this.$route.query.title_2 ? this.$route.query.title_2 : '',
          arrow_1: this.$route.query.arrow_1 ? this.$route.query.arrow_1 : '',
          arrow_2: this.$route.query.arrow_2 ? this.$route.query.arrow_2 : '',
          duration_1: this.$route.query.duration_1 ? this.$route.query.duration_1 : '',
          duration_2: this.$route.query.duration_2 ? this.$route.query.duration_2 : '',
        }
      });

      this.$store.dispatch('GET_TRANSPORT',{ panelId: this.$route.query.panel_id } );
    });
  },
  mounted() {
    // loadYmap();
    window.idleTimer = null;
    window.idleWait = process.env.NODE_ENV == "development" ? 1000000 : 45000;

    // const listener = () => {
    //   clearTimeout(window.idleTimer);
    //   window.idleTimer = setTimeout(() => {
    //     if (["StoryScreen", "header"].includes(this.$route.name) ) listener();
    //     else this.goToStartScreen();
    //   }, window.idleWait);
    // };
    //
    // listener();
    document.querySelector("body").addEventListener("mousemove", this.setHomeTimeout); // its realy needed?
    document.querySelector("body").addEventListener("touchstart", this.setHomeTimeout);
    document.querySelector("body").addEventListener("keydown", this.setHomeTimeout);
    this.setHomeTimeout()
    // console.log('this.$route.query.panel_id', this.$route.query)
    // this.GET_STOP(localStorage.getItem('stopId'));
  },

  methods: {
    ...mapActions(["GET_TRANSPORT", "GET_STOP"]),

    goToStartScreen() {
      if (this.$route.path !== "/") this.$router.push({ path: "/" });
    },

    showRoute(item) {
      this.route = item;
    },
    setHomeTimeout() {

      clearTimeout(window.idleTimer);
      window.idleTimer = setTimeout(() => {
        if (["StoryScreen", "header"].includes(this.$route.name) ) this.setHomeTimeout();
        else this.goToStartScreen();
      }, window.idleWait);
    }
  },

  watch: {
    // stopId: function(newVal) {
    //   if (newVal !== undefined) {
    //     localStorage.setItem('stopId', newVal)
    //   }
    //
    //   this.GET_STOP(localStorage.getItem('stopId'));
    //   setSocketRoutes(localStorage.getItem('stopId'));
    // },
    '$route': {
      handler: function (val) {
        window.idleWait = val.name === "Transport" ? 600000 : 45000;
        this.setHomeTimeout()
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/main.scss";
.leaflet-bottom {
  display: none;
}
</style>
