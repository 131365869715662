//socket init
import socketClusterClient from "socketcluster-client";
import store from "@/store/index.js";
import axios from "axios";
import config from "./config";

const socketConfig = {
  hostname: config.baseSocketURL
}

if (config.socketSecure) {
  socketConfig.port = 443
  socketConfig.secure = true
}

const socket = socketClusterClient.create(socketConfig);

(async () => {
  for await (let { error } of socket.listener('error')) {
    console.error(error);
  }
})();

(async () => {
  for await (let event of socket.listener('connect')) {
    console.log(event, 'Socket is connected');
  }
})();

const getCurrentStopRoutes = function(stopId) {
  return axios
    .get(`${process.env.VUE_APP_TRANSPORT_URL}/stop/${stopId}`)
    .then(res => {
      const fullRoutesSet = [...res.data.data.direct_routes, ...res.data.data.backward_routes];

      const uniqueRoutesSet = Array.from(new Set(fullRoutesSet.map(a => a.id)))
        .map(id => {
          return fullRoutesSet.find(a => a.id === id);
        });

      return uniqueRoutesSet.map(item => item.id);
    });
}

const subscribeRoutes = function(routeId) {
  (async () => {
    let channel = socket.subscribe(`ROUTE.${routeId}`);

    for await (let data of channel) {
      store.commit("SET_ROUTE", data);
    }
  })();
}

const setSocketRoutes = function(stopId) {
  if (typeof stopId === "string") {
    (async () => {
      let channel = socket.subscribe(`STOP.${stopId}`);

      for await (let data of channel) {
        store.commit("SET_CURRENT_ROUTES", data);
      }
    })();

    getCurrentStopRoutes(stopId).then(res => {
      for (let i = 0; i < res.length; i++) {
        subscribeRoutes(res[i]);
      }
    });
  }
}

export { setSocketRoutes, subscribeRoutes, socket };


