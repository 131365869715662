const getDefaultState = () => {
  return {
    coord: null,
    city: null,
    address: null,
    zoom: null,
    stopId: null,
    isStop: '',
    panelCoords: null,
    nearStopCoords: null,
    arrowsAndTitles: null,
  };
};

const state = getDefaultState();

const actions = {
  fetchPanel: ({ commit }, payload) => {
    api.getPanel(payload.panelId).then(res => {
      commit("setPanel", res.data[0]);
      commit("setStop", payload.stopId);
      // commit("setIsStop", payload.isStop);
      commit("setCustomPanelCoords", payload.panelCoords);
      commit("setNearStopCoords", payload.nearStopCoords);
      commit("setArrowsAndTitles", payload.arrowsAndTitles);
    });
    // api.getBusWithStop(payload.panelId)
    //   .then(res => {
    //     commit("setIsStop", res.title);
    //   })
  },
};

const getters = {
  coord: state => state.coord,
  zoom: state => state.zoom,
  title: state => state.title,
  stopId: state => state.stopId,
  isStop: state => state.isStop,
  panelCoords: state => state.panelCoords,
  nearStopCoords: state => state.nearStopCoords,
  arrowsAndTitles: state => state.arrowsAndTitles,
};

const mutations = {
  setPanel(state, res) {
    state.coord = res.location;
    state.city = res.city_id;
    state.address = res.address;
    state.zoom = res.zoom ? res.zoom : 10;
    state.title = res.title;
  },
  setStop(state, id) {
    state.stopId = id;
  },
  setIsStop(state, isStop) {
    state.isStop = isStop;
  },
  setCustomPanelCoords(state, coords) {
    state.panelCoords = coords;
  },
  setNearStopCoords(state, coords) {
    state.nearStopCoords = coords;
  },
  setArrowsAndTitles(state, arrowsAndTitles) {
    state.arrowsAndTitles = arrowsAndTitles;
  },
};

export default {
  state,
  actions,
  getters,
  mutations
};
