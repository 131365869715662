var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper-main-top"},[_vm._m(0),_c('div',{staticClass:"wrapper-main-top__info"},[_c('div',{staticClass:"infobox"},[_c('div',{staticClass:"infobox__date"},[_c('div',{staticClass:"date"},[_c('div',{staticClass:"date__time"},[_c('div',{staticClass:"date-time"},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.realTime,"LT")))])])]),_c('div',{staticClass:"date__day"},[_c('div',{staticClass:"date-day"},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(new Date(),"D MMMM, dddd")))])])])])]),(_vm.weather.real && _vm.weather.day && _vm.weather.night && _vm.weather.icon)?_c('div',{staticClass:"infobox__weather"},[_c('div',{staticClass:"weather"},[_c('div',{staticClass:"weather__degree"},[_c('div',{staticClass:"weather-degree"},[_c('div',{staticClass:"weather-degree__ico"},[_c('div',{staticClass:"weather-degree-ico"},[_c('img',{attrs:{"src":`/img/weather/${_vm.weather.icon}.png`,"alt":""}})])]),_c('div',{staticClass:"weather-degree__text"},[_c('div',{staticClass:"weather-degree-text"},[_c('span',[_vm._v(_vm._s(_vm.weather.real)+"°")])])])])]),_c('div',{staticClass:"weather__highlow"},[_c('div',{staticClass:"weather-highlow"},[_c('div',{staticClass:"weather-highlow__item"},[_c('div',{staticClass:"weather-highlow-item"},[_c('span',[_vm._v("Днём: "+_vm._s(_vm.weather.day)+"°")])])]),_c('div',{staticClass:"weather-highlow__item"},[_c('div',{staticClass:"weather-highlow-item"},[_c('span',[_vm._v("Ночью: "+_vm._s(_vm.weather.night)+"°")])])])])])])]):_vm._e()])]),_c('div',{staticClass:"wrapper-main-top__place"},[_c('div',{staticClass:"place"},[_c('div',{staticClass:"place-items"},[_c('div',{staticClass:"place__item"},[_c('div',{staticClass:"place-item"},[_c('div',{staticClass:"place-item__ico"},[_c('div',{staticClass:"place-item-ico",class:_vm.arrow_1},[(
                    _vm.arrow_1=='s' ||
                    _vm.arrow_1=='n' ||
                    _vm.arrow_1=='w' ||
                    _vm.arrow_1=='e')?_c('img',{attrs:{"src":"/img/arrow-ico-1.svg","alt":""}}):_c('img',{attrs:{"src":"/img/arrow-ico-2.svg","alt":""}})])]),_c('div',{staticClass:"place-item__textblock"},[_c('div',{staticClass:"place-item-textblock"},[_c('div',{staticClass:"place-item__text"},[_c('div',{staticClass:"place-item-text"},[_c('span',[_vm._v(_vm._s(_vm.title_1))])])]),_c('div',{staticClass:"place-item__time"},[_c('div',{staticClass:"place-item-time"},[_c('span',[_vm._v(_vm._s(_vm.duration_1))])])])])])])]),_c('div',{staticClass:"place__item"},[_c('div',{staticClass:"place-item"},[_c('div',{staticClass:"place-item__ico"},[_c('div',{staticClass:"place-item-ico",class:_vm.arrow_2},[(
                    _vm.arrow_2=='s' ||
                    _vm.arrow_2=='n' ||
                    _vm.arrow_2=='w' ||
                    _vm.arrow_2=='e')?_c('img',{attrs:{"src":"/img/arrow-ico-1.svg","alt":""}}):_c('img',{attrs:{"src":"/img/arrow-ico-2.svg","alt":""}})])]),_c('div',{staticClass:"place-item__textblock"},[_c('div',{staticClass:"place-item-textblock"},[_c('div',{staticClass:"place-item__text"},[_c('div',{staticClass:"place-item-text"},[_c('span',[_vm._v(_vm._s(_vm.title_2))])])]),_c('div',{staticClass:"place-item__time"},[_c('div',{staticClass:"place-item-time"},[_c('span',[_vm._v(_vm._s(_vm.duration_2))])])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper-main-top__toptext"},[_c('div',{staticClass:"toptext"},[_c('span',[_vm._v("Гид путеводитель: Ханты-Мансийск")])])])
}]

export { render, staticRenderFns }