import axios from "axios";

const state = {
  stopInfo: {},
  transport: [],
  currentStop: [],
  geometryRoutes: [],
  currentRouteSchedule: [],
  scheduleStationRoutes: [],
  listRoutesPassingBusStop: [],
  listRoutesWithDirectionDirect: [],
  listRoutesWithDirectionBackward: []
}

const getters = {
  BUS_STATION: state => {
    return state.transport.stops;
  },
  GET_STOP_INFO: state => {
    return state.stopInfo;
  },
  GET_STOP: state => {
    return state.currentStop;
  },
  GEOMETRY_ROUTES: state => {
    return state.geometryRoutes.map(el => {
      return [el.Lat, el.Lon];
    });
  },
  GET_ROUTE_SCHEDULE: state => {
    return state.currentRouteSchedule;
  },
  SCHEDULE_STATION_ROUTES: state => {
    return state.scheduleStationRoutes;
  },
  LIST_ROUTES_PASSING_BUS_STOP: state => {
    return state.listRoutesPassingBusStop;
  },
  LIST_ROUTES_DIRECTION: state => {
    let track = state.listRoutesWithDirectionDirect.concat(state.listRoutesWithDirectionBackward);
    return track.map(el => {
      return [el.Lat, el.Lon];
    })
  }
}

const actions = {
  GET_TRANSPORT: async ({ commit }, payload) => {
    const transport = await api.getBusWithStop(payload.panelId)
    // let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/stops/${process.env.VUE_APP_TEMPORY_CAP_REGION_ID}`);
    console.log('transport', transport)
    commit("SET_STOP_INFO", transport);
    commit("SET_STOP", transport.transport_routes);
  },

  GET_STOP: async ({ commit }, payload) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/stop/${payload}`);
    commit("SET_STOP", data.data);
  },

  GET_GEOMETRY_ROUTES: async ({ commit }, payload) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/track_dir/${payload.routeId}/${payload.stopId}`)
    commit("SET_GEOMETRY_ROUTES", data.data);
  },

  GET_ROUTE_SCHEDULE_FROM_SERVER: async ({ commit }, payload) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/schedule_day/${payload.params.routeId}/${payload.params.stopId}`);
    commit("SET_ROUTE_SCHEDULE", data.data);
  },

  GET_SCHEDULE_STATION_ROUTES: async ({ commit }, payload) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/schedule_dir/${payload.routeId}/${payload.stopId}`);
    commit("SET_SCHEDULE_STATION", data.data);
  },

  GET_LIST_ROUTES_PASSING_BUS_STOP: async ({commit}, busStationId) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/routes/${busStationId}`);
    commit("SET_LIST_ROUTES_PASSING_BUS_STOP", data.data)
  },

  GET_LIST_ROUTES_WITH_DIRECTION_DIRECT: async ({commit}, payload) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/track/${payload.routeId}/${payload.direction}`);
    commit("SET_LIST_ROUTES_WITH_DIRECTION_DIRECT", data.data);
  },

  GET_LIST_ROUTES_WITH_DIRECTION_BACKWARD: async ({commit}, payload) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/track/${payload.routeId}/${payload.direction}`);
    commit("SET_LIST_ROUTES_WITH_DIRECTION_BACKWARD", data.data);
  }

}

const mutations = {
  SET_STOP_INFO: (state, payload) => {
    state.stopInfo = payload;
  },
  SET_TRANSPORT: (state, payload) => {
    state.transport = payload;
  },
  SET_STOP: (state, payload) => {
    state.currentStop = payload;
  },
  SET_GEOMETRY_ROUTES: (state, payload) => {
    state.geometryRoutes = payload;
  },
  SET_ROUTE_SCHEDULE: (state, payload) => {
    state.currentRouteSchedule = payload;
  },
  SET_SCHEDULE_STATION: (state, payload) => {
    state.scheduleStationRoutes = payload;
  },
  SET_LIST_ROUTES_PASSING_BUS_STOP: (state, payload) => {
    state.listRoutesPassingBusStop = payload;
  },
  SET_LIST_ROUTES_WITH_DIRECTION_DIRECT: (state, payload) => {
    state.listRoutesWithDirectionDirect = payload;
  },
  SET_LIST_ROUTES_WITH_DIRECTION_BACKWARD: (state, payload) => {
    state.listRoutesWithDirectionBackward = payload;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
