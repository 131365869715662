<template>
  <div class="wrapper-main-top">
    <div class="wrapper-main-top__toptext">
      <div class="toptext">
        <span>Гид путеводитель: Ханты-Мансийск</span>
      </div>
    </div>
    <div class="wrapper-main-top__info">
      <div class="infobox">
        <div class="infobox__date">
          <div class="date">
            <div class="date__time">
              <div class="date-time">
                <span>{{ realTime | moment("LT") }}</span>
              </div>
            </div>
            <div class="date__day">
              <div class="date-day">
                <span>{{ new Date() | moment("D MMMM, dddd") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
            class="infobox__weather"
            v-if="weather.real && weather.day && weather.night && weather.icon"
        >
          <div class="weather">
            <div class="weather__degree">
              <div class="weather-degree">
                <div class="weather-degree__ico">
                  <div class="weather-degree-ico">
                    <img :src="`/img/weather/${weather.icon}.png`" alt />
                  </div>
                </div>
                <div class="weather-degree__text">
                  <div class="weather-degree-text">
                    <span>{{weather.real}}°</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="weather__highlow">
              <div class="weather-highlow">
                <div class="weather-highlow__item">
                  <div class="weather-highlow-item">
                    <span>Днём: {{weather.day}}°</span>
                  </div>
                </div>
                <div class="weather-highlow__item">
                  <div class="weather-highlow-item">
                    <span>Ночью: {{weather.night}}°</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper-main-top__place">
      <div class="place">
        <div class="place-items">
          <div class="place__item">
            <div class="place-item">
              <div class="place-item__ico">
                <div class="place-item-ico" :class="arrow_1">
                  <img
                      src="/img/arrow-ico-1.svg"
                      alt
                      v-if="
                      arrow_1=='s' ||
                      arrow_1=='n' ||
                      arrow_1=='w' ||
                      arrow_1=='e'"
                  />
                  <img src="/img/arrow-ico-2.svg" alt v-else />
                </div>
              </div>
              <div class="place-item__textblock">
                <div class="place-item-textblock">
                  <div class="place-item__text">
                    <div class="place-item-text">
                      <span>{{title_1}}</span>
                    </div>
                  </div>
                  <div class="place-item__time">
                    <div class="place-item-time">
                      <span>{{duration_1}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="place__item">
            <div class="place-item">
              <div class="place-item__ico">
                <div class="place-item-ico" :class="arrow_2">
                  <img
                      src="/img/arrow-ico-1.svg"
                      alt
                      v-if="
                      arrow_2=='s' ||
                      arrow_2=='n' ||
                      arrow_2=='w' ||
                      arrow_2=='e'"
                  />
                  <img src="/img/arrow-ico-2.svg" alt v-else />
                </div>
              </div>
              <div class="place-item__textblock">
                <div class="place-item-textblock">
                  <div class="place-item__text">
                    <div class="place-item-text">
                      <span>{{title_2}}</span>
                    </div>
                  </div>
                  <div class="place-item__time">
                    <div class="place-item-time">
                      <span>{{duration_2}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBarPage',
  data() {
    return {
      realTime: this.$moment(),
      weather: {
        real: null,
        day: null,
        night: null,
        icon: null
      },
      title_1: null,
      title_2: null,
      arrow_1: null,
      arrow_2: null,
      duration_1: null,
      duration_2: null
    };
  },
  mounted() {
    // console.log(this.$route.query);
    setInterval(() => {
      this.realTime = this.$moment();
    }, 1000);

    this.fetchWeather();
    setInterval(() => this.fetchWeather(), 1000 * 60 * 10);

    this.title_1 =
        typeof this.$route.query.title_1 == "object"
            ? this.$route.query.title_1[0]
            : this.$route.query.title_1;
    this.title_2 =
        typeof this.$route.query.title_2 == "object"
            ? this.$route.query.title_2[0]
            : this.$route.query.title_2;
    this.arrow_1 =
        typeof this.$route.query.arrow_1 == "object"
            ? this.$route.query.arrow_1[0]
            : this.$route.query.arrow_1;
    this.arrow_2 =
        typeof this.$route.query.arrow_2 == "object"
            ? this.$route.query.arrow_2[0]
            : this.$route.query.arrow_2;
    this.duration_1 =
        typeof this.$route.query.duration_1 == "object"
            ? this.$route.query.duration_1[0]
            : this.$route.query.duration_1;
    this.duration_2 =
        typeof this.$route.query.duration_2 == "object"
            ? this.$route.query.duration_2[0]
            : this.$route.query.duration_2;
  },
  methods: {
    fetchWeather() {
      let status = true;
      api.getWeather().then(res => {
        if (!res.weather || !res.main) status = false;
        this.weather.real = Math.round(res.main.temp);
        this.weather.icon = res.weather[0].icon;
      });
      api.getDailyWeather().then(res => {
        if (!res.list || !res.list[0].temp) status = false;
        this.weather.day = Math.round(res.list[0].temp.day);
        this.weather.night = Math.round(res.list[0].temp.night);
      });
      if (!status)
        setTimeout(() => {
          this.fetchWeather();
        }, 100);
    }
  }
};
</script>

<style lang="scss">
.place-item-ico {
  &.w img {
    transform: rotate(180deg);
  }
  &.n img {
    transform: rotate(-90deg);
  }
  &.s img {
    transform: rotate(90deg);
  }
  &.nw img {
    transform: rotate(180deg);
  }
  &.sw img {
    transform: rotate(90deg);
  }
  &.ne img {
    transform: rotate(-90deg);
  }
}
</style>
