import axios from "axios";

const getDefaultState = () => {
  return {
    events: [],
    selectedEvent: null,
    loader: false,
  };
};

const state = getDefaultState();

const actions = {
  fetchEvents: ({ commit }, dates) => {
    commit("setEvents", []);

    api.getEvents(dates).then(res => {
      commit("setEvents", res.data);
    });
  },

  GET_EVENTS_FROM_SERVER: async ({commit}, payload) => {
    const today = new Date();
    const started = today.toISOString().split('T')[0];
    commit("SET_EVENTS_LOADER", true)
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/events?started_at=${started}&sort=created_at&resolution=medium&page=${payload.page}&count=${payload.count}`);

    setTimeout(() => {
      if (data.result === "ok") {
        commit("SET_EVENTS", data.data);
        commit("SET_EVENTS_LOADER", false);
      }
    }, 500)
  },

  setSelectedEvent: ({ commit }, data) => {
    commit("setSelectedEvent", data);
  }
};

const getters = {
  GET_EVENTS: state => state.events,
  GET_EVENTS_LOADER: (state) => state.loader,
  selectedEvent: state => state.selectedEvent
};

const mutations = {
  SET_EVENTS(state, res) {
    state.events = state.events.concat(res);
  },
  SET_EVENTS_LOADER(state, res) {
    state.loader = res;
  },
  SET_CLEAR_EVENTS: (state) => {
    state.events = [];
  },
  setSelectedEvent(state, res) {
    state.selectedEvent = res;
  },
  setEvents(state, res) {
    state.events = res;
  },

};

export default {
  state,
  actions,
  getters,
  mutations
};
